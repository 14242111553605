<template>
  <div>
    <v-dialog
      classs="CitaDetalle"
      v-model="showDialog"
      width="350"
    >
      <v-card
        v-if="!showDeleteDialog"
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>Información de la cita</v-card-title>
        <v-list>
          <v-divider />
          <v-list-item>
            <v-list-item-title><strong>Fecha:</strong> {{$Datetime.fromSQL(cita.fecha).setLocale('es').toLocaleString($Datetime.DATE_FULL)}}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-title><strong>Horario:</strong> {{$Datetime.fromSQL(cita.fecha).setLocale('es').toFormat('h:mm a')}}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-title><strong>Dentista:</strong> {{cita.medico}}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <div v-if="cita.servicios.length>=1">
            <v-subheader style="margin-bottom:-16px">Motivo de la cita</v-subheader>
            <div
              v-for="(ser,idx) in cita.servicios"
              :key="idx"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title nowrap>{{ser.servicio}}</v-list-item-title>
                  <v-list-item-subtitle>Desde {{$nF(ser.precio)}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </div>
          </div>
        </v-list>
        <v-card-actions>
          <v-btn
            block
            v-text="'Cancelar cita'"
            @click="showDeleteDialog=true"
            plain
          />
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            class="primary"
            depressed
            v-text="'Cerrar'"
            @click="showDialog=false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      v-model="showDeleteDialog"
      errorMessage="¿Estás seguro que deseas cancelar tu cita?"
      errorMessageDetail="Una vez cancelada no podrás revertir la acción y deberás reagendar otra fecha."
      buttonMessage="Si, cancelar cita"
      @closed="showDeleteDialog=false"
      @confirmDelete="_cancelarCita"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  name: "CitaDetalle",
  props: {
    value: String,
  },
  components: {
    DeleteDialog,
  },
  mixins: [forms],
  data() {
    return {
      showDialog: false,
      cita: {
        cita: null,
        fecha: null,
        id: null,
        medico: null,
        servicios: [],
      },
      showDeleteDialog: false,
    };
  },
  methods: {
    _getCita() {
      if (this.value) {
        this._getThings("citas/" + this.value, "cita");
      }
    },
    _checkIdCita() {
      this.showDialog = this.cita.id ? true : false;
    },
    _cancelarCita() {
      this.showDeleteDialog = false;
      this._deleteAll("citas/" + this.cita.id, "msg", "_handleCitaDeleted");
    },
    _handleCitaDeleted(res) {
      this.$emit("refresh", true);
      this.$emit("msg", res.msg);
      this.showDialog = false;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: "_getCita",
    },
    cita: {
      deep: true,
      immediate: true,
      handler: "_checkIdCita",
    },
    showDialog(a) {
      if (!a) {
        this.$emit("input", null);
        this.cita = {
          cita: null,
          fecha: null,
          id: null,
          medico: null,
          servicios: [],
        };
      }
    },
  },
};
</script>