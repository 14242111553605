export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
    },
    created() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
}