<template>
  <v-btn
    icon
    text
    dark
    class="primary"
    @click="$emit('click')"
    :to="to"
  >
    <v-icon v-text="'add'" />
  </v-btn>
</template>
<script>
export default {
  props: {
    to: String,
  },
};
</script>