<template>
  <div class="CargosResumen">
    <v-card
      shaped
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-text>
        <h1
          align="center"
          class="mt24"
        >{{$nF(saldo)}}</h1>
        <p align="center"><small>Tu adeudo total</small></p>
      </v-card-text>
      <v-list v-if="cargos.length>=1">
        <div
          v-for="(c,idxc) in cargos"
          :key="idxc"
        >
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'Pendiente: '+$nF(c.pendiente)" />
              <v-list-item-subtitle v-text="'Adeudo original: '+$nF(c.importe)" />
              <v-list-item-subtitle class="nowrap" v-text="'Tratamiento realizado desde el  '+_doDiaMes(c.fecha)" />
              <v-list-item-subtitle
                class="nowrap"
                v-text="'Servicios: '+c.servicios"
              />
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-btn icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "CargosResumen",
  mixins: [forms, dates],
  data() {
    return {
      saldo: null,
      cargos: [{ a: 1 }],
    };
  },
  methods: {
    _getResumen() {
      this._getThings("cargos/saldo", "saldo"); //asssd
    },
    _getCargosPendientes() {
      this._getThings("cargos/pendientes", "cargos"); //123
    },
  },
  created() {
    this._getResumen();
    this._getCargosPendientes();
  },
};
</script>