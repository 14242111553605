<template>
  <div class="PacienteInformacion">
    <v-card
      shaped
      outlined
      :loading="cargando"
      :disabled="cargando"
      color="primary"
      dark
    >
      <v-app-bar
        flat
        color="transparent"
      >
        <v-spacer />
        <v-btn
          icon
          v-bind="attrs"
          to="/login"
        >
          <v-icon>exit_to_app</v-icon>
        </v-btn>
        <!-- <v-menu> <template v-slot:activator="{ on, attrs }"> <v-btn icon v-bind="attrs" v-on="on" > <v-icon>more_vert</v-icon> </v-btn> </template> <v-list light> <v-list-item to="/paciente"><v-list-item-title v-text="'Editar perfil'" /></v-list-item><v-divider /> <v-list-item to="/login"> <v-list-item-title v-text="'Cerrar sesión'" /> </v-list-item> </v-list> </v-menu> -->
      </v-app-bar>
      <v-card-title
        style="heigth:auto!important"
        @click="$rotuer.push('/paciente')"
      >
        <v-avatar
          size="56"
          @click="_handleClickAvatar"
        >
          <v-img
            v-if="fotoPersona"
            alt="usuario"
            :src="'http://localhost/dentalmaster/dentalmaster-admin-api/uploads/imagenes/' + fotoPersona"
          />
          <v-img
            v-else
            :src="require('@/assets/avatars/profiles/perfil-generico-0' + perfil + '.png')"
          />
        </v-avatar>
        <p
          class="ml-3"
          style="text-transform:capitalize"
        >
          {{ $session.get('nombreCompleto') }}
        </p>
        <v-spacer />
        <v-btn
          icon
          to="/paciente"
        >
          <v-icon>edit</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-dialog
      fullscreen
      scrollable
      v-model="showImageModal"
    >
      <v-card>
        <v-card-title>Fotografía de perfil</v-card-title>
        <v-card-text style="text-align:center">
          <div width="100%">
            <v-avatar
              size="240"
              @click="_handleClickAvatar"
            >
              <v-img
                v-if="fotoPersona"
                alt="usuario"
                :src="'http://localhost/dentalmaster/dentalmaster-admin-api/uploads/imagenes/' + fotoPersona"
              />
              <v-img
                v-else
                :src="require('@/assets/avatars/profiles/perfil-generico-0' + perfil + '.png')"
              />
            </v-avatar>
          </div>
          <div class="mt24">
            <v-btn
              v-if="!showFileInput"
              color="primary"
              type="file"
              @click="showFileInput = true"
            >
              Cambiar imágen
            </v-btn>
            <div v-else>
              <v-file-input
                label="Seleccionar fotografía"
                filled
                prepend-icon=""
                prepend-inner-icon="photo_camera"
                v-model="file"
                :disabled="cargando"
              />
              <v-btn color="primary" :disabled="!file || cargando" :loading="cargando" @click="_guardarImagen">Guardar</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="_handleClickAvatar; showFileInput = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "PacienteInformacion",
  mixins: [forms],
  data() {
    return {
      perfil: null,
      showImageModal: false,
      showFileInput: false,
      file: null,
      cargando:false,
      fotoPersona:null,
    };
  },
  methods: {
    _selectRandomProfile() {
      const numero = Math.floor(Math.random() * (4 - 1 + 1) + 1);
      this.perfil = numero;
    },
    _handleClickAvatar() {
      this.showImageModal = !this.showImageModal;
    },
    async _guardarImagen() {
      this.cargando = true;
      try {
        let data = new FormData();
        data.append("imagen", this.file);
        const { status, body } = await this.$http.post(
          "paciente/foto",
          data,
          this.$headers
        );
        this.cargando = false;
        if ("msg" in body) {
          this.$emit("msg", body.msg);
          this.showImageModal = false;
          this.file = null;
          this.$session.set('foto',body.imagen)
          this.fotoPersona = body.imagen
          this._getPaciente();
        } else {
          this.$emit("msg", body.error);
        }
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
  },
  created() {
    this.fotoPersona = this.$session.get('foto');
    this._selectRandomProfile();
  },
};
</script>