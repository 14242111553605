<template>
  <div class="CitasProximas">
    <v-card
      shaped
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title>Próximas citas</v-card-title>
      <v-card-actions>
        <v-btn to="/cita" block color="primary" outlined>Agenda tu cita <v-icon>chevron_right</v-icon></v-btn>
      </v-card-actions>
      <v-list>
        <div v-if="citas.length>=1">
          <div
            v-for="cita in citas"
            :key="cita.id"
          >
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="nowrap">
                  {{_ucFirst($Datetime.fromSQL(cita.fecha).setLocale('es').toLocaleString($Datetime.DATE_FULL))}} a las {{$Datetime.fromSQL(cita.fecha).setLocale('es').toFormat('h:mm a')}}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="idCita = cita.id"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </div>
        <div v-else>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'Por el momento no tienes citas próximas'" />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
    <citas-detalle
      v-model="idCita"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @refresh="_getCitasProximas"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import windowResize from "@/mixins/windowResize.js";
import ButtonAdd from "@/components/forms/buttons/ButtonAdd.vue";
import CitasDetalle from "@/components/citas/CitasDetalle.vue";
export default {
  name: "CitasProximas",
  mixins: [forms, windowResize],
  components: { ButtonAdd, CitasDetalle },
  data() {
    return {
      showDialogNuevaCita: false,
      citas: [],
      idCita: null,
    };
  },
  methods: {
    _getCitasProximas() {
      this._getThings("citas", "citas");
    },
    _ucFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  created() {
    this._getCitasProximas();
  },
};
</script>