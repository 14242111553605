<template>
  <v-dialog
    persistent
    dark
    v-model="value"
    width="360"
  >
    <v-card :color="$error">
      <v-card-title
        class="nowrap"
        v-text="errorMessage"
      />
      <v-card-text
        class="nowrap"
        v-if="errorMessageDetail"
      >
        {{errorMessageDetail}}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          @click="_closeDialog"
        />
        <v-btn
          light
          class="white"
          text
          @click="$emit('confirmDelete',Date.now())"
          v-text="buttonMessage ? buttonMessage : 'Si, eliminar'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    errorMessage: String,
    errorMessageDetail: String,
    buttonMessage: String,
  },
  methods: {
    _closeDialog() {
      this.value = false;
      this.$emit("closed", true);
    },
  },
};
</script>