<template>
  <div class="Home">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <paciente-informacion
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <citas-proximas
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
      >
        <CargosResumen
          @msg="$emit('msg',$event)"
          @httpError="$emit('httpError',$event)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import CitasProximas from "@/components/citas/CitasProximas.vue";
import PacienteInformacion from "@/components/pacientes/PacienteInformacion.vue";
import CargosResumen from "@/components/cargos/CargosResumen.vue";
export default {
  name: "Home",
  mixins: [standard],
  components: {
    CitasProximas,
    PacienteInformacion,
    CargosResumen,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
