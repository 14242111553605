import { DateTime, Interval } from "luxon";
export default {
    data() { return {} },
    methods: {
        _today() {
            return DateTime
                .now()
                .setZone("America/Chihuahua")
                .toISODate()
        },
        _isValidDate(fecha) {
            const res = DateTime
                .fromSQL(fecha)
                .isValid
            return res;
        },
        _dt(fecha) {
            return DateTime
                .fromSQL(fecha, { setZone: false })
                .setLocale('es-Mx');
        },
        _doHoras(fecha) {
            return DateTime.fromSQL(fecha).toFormat('t');
        },
        _doEdad(fecha) {
            const fechaNac = this._dt(fecha);
            var { years } = DateTime.now().diff(fechaNac, "years").toObject();
            return Math.floor(years);
        },
        _doDiaMes(fecha) {
            if (!fecha) {
                return ''
            }
            return this._dt(fecha).toFormat('DDD');
            // return dT.toFormat('DDD');
        },
        _doDiaMesHoras(fecha, showDia = false) {
            if (!fecha) {
                return ''
            }
            const f = showDia ? 'DDDD' : 'DDD'
            return this._dt(fecha).toFormat(`${f} 'a las' t`);
        },
        _doMesAnio(fecha) {
            return this._dt(fecha).toFormat('MMMM yyyy')
        },
        _datesLong(fecha) {
            DateTime.local()
            return ''
        }
    }
}